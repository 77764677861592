<script>
  let directoryHandle = null;
  let fileTree = [];
  let duplicates = [];

  // Function to handle folder selection
  async function selectFolder() {
    try {
      directoryHandle = await window.showDirectoryPicker();
      // Use the updated traverseDirectory function
      fileTree = await traverseDirectory(directoryHandle);
      findDuplicates();
    } catch (error) {
      console.error("Error selecting folder:", error);
    }
  }

  // Updated function to traverse the directory recursively
  async function traverseDirectory(dirHandle, path = "") {
    let files = [];
    for await (const [name, handle] of dirHandle.entries()) {
      const currentPath = `${path}/${name}`;
      if (handle.kind === "file") {
        const file = await handle.getFile();
        files.push({
          name: file.name,
          size: file.size,
          path: currentPath,
          type: "file",
        });
      } else if (handle.kind === "directory") {
        const subFiles = await traverseDirectory(handle, currentPath);
        files = files.concat(subFiles);
      }
    }
    return files;
  }

  // Function to find duplicate files based on file size
  function findDuplicates() {
    const sizeMap = {};
    for (const file of fileTree) {
      if (file.type === "file") {
        if (sizeMap[file.size]) {
          sizeMap[file.size].push(file);
        } else {
          sizeMap[file.size] = [file];
        }
      }
    }
    duplicates = Object.values(sizeMap)
      .filter((group) => group.length > 1)
      .flat();
  }

  // Helper function to check if a file is a duplicate
  function isDuplicate(file) {
    return duplicates.some((dup) => dup.path === file.path);
  }
</script>

<div>
  <h2>
    Select a folder on your computer and I will show you any duplicate files
  </h2>
  <button on:click={selectFolder}>Select Folder</button>

  {#if fileTree.length}
    <div class="file-explorer">
      {#each fileTree as item}
        {#if item.type === "file"}
          <div
            class="item file {isDuplicate(item) ? 'duplicate' : ''}"
            title={item.path}
          >
            {item.name}
          </div>
        {/if}
      {/each}
    </div>
  {/if}
</div>

<style>
  /* Styles for the app */
  h2 {
    font-family: Arial, sans-serif;
  }
  button {
    margin-top: 10px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
  }
  .file-explorer {
    margin-top: 20px;
    font-family: Arial, sans-serif;
  }
  .item {
    padding-left: 20px;
    line-height: 1.5;
  }
  .file::before {
    content: "📄 ";
  }
  .directory::before {
    content: "📁 ";
  }
  .duplicate {
    background-color: #ffeb3b;
  }
</style>
